import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'

import Layout from '../components/Layout'
import HeaderSEO from '../components/partials/HeaderSEO'
import { StaticImage } from 'gatsby-plugin-image'

import article_logo from '../resources/images/case_studies/article/article-logo.svg'

import playtika_white_logo from '../resources/images/clients/playtika/playtika_white_logo.png'
import playtika_bg from '../resources/images/clients/playtika/playtika_bg.jpg'

import marine_traffic_white_logo from '../resources/images/clients/marine_traffic/marine_traffic_white_logo.png'
import marine_traffic_bg from '../resources/images/clients/marine_traffic/marine_traffic_bg.jpg'

import vortexa_white_logo from '../resources/images/clients/vortexa/vortexa_white_logo.png'
import vortexa_bg from '../resources/images/clients/vortexa/vortexa_bg.jpg'

import viseca_white_logo from '../resources/images/clients/viseca/viseca_white_logo.png'
import viseca_bg from '../resources/images/clients/viseca/viseca_bg.png'

import article_white_logo from '../resources/images/case_studies/article/article_white_logo.svg'
import article_bg from '../resources/images/case_studies/article/article_bg.jpeg'

import avanza_white_logo from '../resources/images/clients/avanza/avanza_white_logo.png'
import avanza_bg from '../resources/images/clients/avanza/avanza_bg.jpg'

import babylon_white_logo from '../resources/images/clients/babylon/babylon_white_logo.png'
import babylon_bg from '../resources/images/clients/babylon/babylon_bg.png'

import nuvo_white_logo from '../resources/images/clients/nuvo/nuvo-white.svg'
import nuvo_bg from '../resources/images/clients/nuvo/featured-nuvo.png'

import arity_logo from '../resources/images/logos/clients_logos/Arity.svg'
import arity_bg from '../resources/images/case_studies/arity/featured-image-arity.jpg'

const customers = () => {
    const data = useStaticQuery(graphql`
        query CustomersQuery {
            allContentfulClientsLogos {
                edges {
                    node {
                        name
                        logo {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }
            allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "Customers" } } }) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    const customerList = [
        {
            node: {
                backgroundImage: arity_bg,
                imageWhiteLogo: arity_logo,
                slug: '/customers/arity-apache-kafka-costs-amazon-msk/',
                title: 'How Arity accelerated their Kafka adoption and saved costs by 40%',
            },
        },
        {
            node: {
                backgroundImage: article_bg,
                imageWhiteLogo: article_white_logo,
                slug: '/customers/article-wins-marketshare-using-apache-kafka/',
                title: 'How Article is winning market share using Apache Kafka',
            },
        },
        {
            node: {
                backgroundImage: nuvo_bg,
                imageWhiteLogo: nuvo_white_logo,
                slug: '/customers/how-nuvo-processes-real-time-data-with-apache-kafka/',
                title: 'Accessing and processing real-time heart rate data',
            },
        },
        {
            node: {
                backgroundImage: avanza_bg,
                imageWhiteLogo: avanza_white_logo,
                slug: '/customers/avanza/',
                title: 'How developers democratize data across all their Kafka Streams',
            },
        },
        {
            node: {
                backgroundImage: babylon_bg,
                imageWhiteLogo: babylon_white_logo,
                slug: '/videos/babylon-health-dataops-apache-kafka/',
                title: 'Accelerating data engineering to improve the health of millions',
            },
        },
        {
            node: {
                backgroundImage: marine_traffic_bg,
                imageWhiteLogo: marine_traffic_white_logo,
                slug: '/customers/marine-traffic/',
                title: 'How MarineTraffic tracks shipping fleet data with Apache Kafka',
            },
        },
        {
            node: {
                backgroundImage: playtika_bg,
                imageWhiteLogo: playtika_white_logo,
                slug: '/customers/playtika/',
                title: 'How Playtika takes DataOps to production with Lenses',
            },
        },
        {
            node: {
                backgroundImage: vortexa_bg,
                imageWhiteLogo: vortexa_white_logo,
                slug: '/customers/vortexa/',
                title: 'How Lenses makes Kafka smooth sailing at Vortexa',
            },
        },
        {
            node: {
                backgroundImage: viseca_bg,
                imageWhiteLogo: viseca_white_logo,
                slug: '/customers/viseca/',
                title: 'A Streaming Data Platform for real-time customer interactions',
            },
        },
    ]

    return (
        <Layout newsletter={false}>
            <HeaderSEO pageSEO={pageSEO} />
            <section className='pt-5 mt-5 pb-3 customers-hero-img'>
                <div className='container-1'>
                    <div className='d-flex flex-wrap mt-5'>
                        <div className='col-md-8 pt-0 pb-5'>
                            <p className='title breadcrumb-text text-white'>
                                <span className='f-14'>CUSTOMERS</span> /
                                <span className='font-weight-bold text-uppercase pl-2'>
                                    FEATURED
                                </span>
                            </p>
                            <div className='mt-5'>
                                <img src={article_logo} alt='Lenses - Article Case Study' />
                                <h1 className='mt-4 text-white'>
                                    How Article is winning market share using Apache Kafka
                                </h1>
                                <p className='hero-description-dark pt-4 text-white'>
                                    A steep demand spike during the pandemic drove this digital
                                    retail leader to break up their monolith in weeks, to win market
                                    share from their competitors.
                                </p>
                                <div id='dl_action' className='roll-button'>
                                    <a
                                        href='/customers/article-wins-marketshare-using-apache-kafka/'
                                        className='bg-red text-white mt-2'>
                                        <span data-hover='Read case study'>Read case study</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='pt-0'>
                <div className='container-1 pt-4'>
                    <div className='d-flex flex-wrap'>
                        <div className='col-md-12'>
                            <h2 className='paragraph-title f-32 pb-5 pt-5 text-center'>
                                Featured Success stories
                            </h2>
                            <div className='row'>
                                {customerList.map(({ node }) => {
                                    return (
                                        <div className='col-md-3 col-sm-6 col-12' key={uuidv4()}>
                                            <div
                                                className='card mt-3 full-customers-container border-0'
                                                style={{
                                                    background: `url(${node.backgroundImage}) center center`,
                                                }}>
                                                <a
                                                    className='pl-3 pt-3 pr-3 pb-5  mobile-padding-reset'
                                                    href={`${node.slug}`}>
                                                    <img
                                                        src={node.imageWhiteLogo}
                                                        className='img-fluid pl-4 pt-4 pr-4 mb-4'
                                                        alt={node.slug}
                                                    />
                                                    <p className='text-white text-center full-customers-text f-22'>
                                                        {node.title}
                                                    </p>
                                                </a>
                                                <div className='d-flex justify-content-center'>
                                                    <span className='roll-button customers-read-more-button'>
                                                        <a
                                                            className='fw-700 text-center f-12 px-3 py-2 rounded bg-white primary-text'
                                                            href={`${node.slug}`}>
                                                            {node.slug ===
                                                            '/videos/babylon-health-dataops-apache-kafka/' ? (
                                                                <span data-hover='Watch the full story'>
                                                                    Watch the full story
                                                                </span>
                                                            ) : (
                                                                <span data-hover='Read the full story'>
                                                                    Read the full story
                                                                </span>
                                                            )}
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {/* END OF FOR ABOVE ^ */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='p-5 m-5 mobile-padding-reset'>
                <div className='container-2'>
                    <div className='row'>
                        <div className='col-md-12 col-12'>
                            <h3 className='paragraph-title f-32 pb-4 text-center'>
                                Loved by hundreds of companies
                            </h3>
                            <div className='row'>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Namely.svg'
                                        alt='Namely'
                                        placeholder='tracedSVG'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Adidas.svg'
                                        alt='Adidas'
                                        placeholder='tracedSVG'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/SAS.svg'
                                        placeholder='tracedSVG'
                                        alt='SAS'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Carta.svg'
                                        placeholder='tracedSVG'
                                        alt='Carta'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Credimi.svg'
                                        placeholder='tracedSVG'
                                        alt='Credimi'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Bouygues.svg'
                                        placeholder='tracedSVG'
                                        alt='Bouygues'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                            </div>
                            <div className='row pt-4'>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Telenet.svg'
                                        placeholder='tracedSVG'
                                        alt='Telenet'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/OtpBank.svg'
                                        placeholder='tracedSVG'
                                        alt='OtpBank'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/healthfirst.png'
                                        placeholder='tracedSVG'
                                        alt='healthFirst'
                                        className='w-100 logo-graphic logo-graphc-healthfirst'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Generali.svg'
                                        placeholder='tracedSVG'
                                        alt='Generali'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Babylon2.png'
                                        placeholder='tracedSVG'
                                        alt='Babylon2'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Elsevier.png'
                                        placeholder='tracedSVG'
                                        alt='Elsevier'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                            </div>
                            <div className='row pt-4'>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/SchneiderElectric.svg'
                                        placeholder='tracedSVG'
                                        alt='SchneiderElectric'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Cotiviti.svg'
                                        placeholder='tracedSVG'
                                        alt='Cotiviti'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/ElectronicPartner.svg'
                                        placeholder='tracedSVG'
                                        alt='ElectronicPartner'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Cellwize.svg'
                                        placeholder='tracedSVG'
                                        alt='Cellwize'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Emesa.png'
                                        placeholder='tracedSVG'
                                        alt='Emesa'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/EP.svg'
                                        placeholder='tracedSVG'
                                        alt='EP'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                            </div>
                            <div className='row pt-4'>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/EuroInformationTelecom.png'
                                        placeholder='tracedSVG'
                                        alt='EuroInformationTelecom'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Daimler.png'
                                        placeholder='tracedSVG'
                                        alt='Daimler'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/FlightHub.svg'
                                        alt='FlightHub'
                                        placeholder='tracedSVG'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/BML.svg'
                                        alt='BML'
                                        placeholder='tracedSVG'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Cision.svg'
                                        placeholder='tracedSVG'
                                        alt='Cision'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Haulynx.svg'
                                        placeholder='tracedSVG'
                                        alt='Haulynx'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                            </div>
                            <div className='row pt-4'>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/HoneyBook.svg'
                                        placeholder='tracedSVG'
                                        alt='HoneyBook'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/IntelligentLocations.png'
                                        placeholder='tracedSVG'
                                        alt='IntelligentLocations'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Itivity.png'
                                        placeholder='tracedSVG'
                                        alt='Itivity'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/ITS.svg'
                                        placeholder='tracedSVG'
                                        alt='ITS'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/KickBack.svg'
                                        placeholder='tracedSVG'
                                        alt='KickBack'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Komatsu.svg'
                                        placeholder='tracedSVG'
                                        alt='Komatsu'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                            </div>
                            <div className='row pt-4'>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/AGCO.svg'
                                        placeholder='tracedSVG'
                                        alt='AGCO'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Lineas.svg'
                                        placeholder='tracedSVG'
                                        alt='Lineas'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/90poe.svg'
                                        placeholder='tracedSVG'
                                        alt='n90poe'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Nuvo.png'
                                        placeholder='tracedSVG'
                                        alt='Nuvo'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/octanner.svg'
                                        placeholder='tracedSVG'
                                        alt='octanner'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Omise.svg'
                                        placeholder='tracedSVG'
                                        alt='Omise'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                            </div>
                            <div className='row pt-4'>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/PickMe.svg'
                                        placeholder='tracedSVG'
                                        alt='PickMe'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/DrKlein.png'
                                        placeholder='tracedSVG'
                                        alt='DrKlein'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Accenture.svg'
                                        placeholder='tracedSVG'
                                        alt='Accenture'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Schneider.svg'
                                        placeholder='tracedSVG'
                                        alt='Schneider'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Compstak.svg'
                                        placeholder='tracedSVG'
                                        alt='Compstak'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Schuler.svg'
                                        placeholder='tracedSVG'
                                        alt='Schuler'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                            </div>

                            <div className='row pt-4'>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/SwedishEmploymentService.svg'
                                        placeholder='tracedSVG'
                                        alt='SwedishEmploymentService'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Blume.svg'
                                        placeholder='tracedSVG'
                                        alt='Blume'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Article.svg'
                                        placeholder='tracedSVG'
                                        alt='Article'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Ubeeqo.svg'
                                        placeholder='tracedSVG'
                                        alt='Ubeeqo'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Viseca.svg'
                                        placeholder='tracedSVG'
                                        alt='Viseca'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/CityFibre.png'
                                        placeholder='tracedSVG'
                                        alt='CityFibre'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                            </div>
                            <div className='row pt-4'>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/TiltingPoint.svg'
                                        placeholder='tracedSVG'
                                        alt='TiltingPoint'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Votorantim.svg'
                                        placeholder='tracedSVG'
                                        alt='Votorantim'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/WireFilter.svg'
                                        placeholder='tracedSVG'
                                        alt='WireFilter'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Cameo.svg'
                                        placeholder='tracedSVG'
                                        alt='Cameo'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Oxio.svg'
                                        placeholder='tracedSVG'
                                        alt='Oxio'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Presidio.svg'
                                        placeholder='tracedSVG'
                                        alt='Presidio'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                            </div>
                            <div className='row pt-4 justify-content-center'>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Flipp.png'
                                        placeholder='tracedSVG'
                                        alt='Flipp'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Geotab.svg'
                                        placeholder='tracedSVG'
                                        alt='Geotab'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mt-3'>
                                    <StaticImage
                                        src='../resources/images/clients/Mondia.svg'
                                        placeholder='tracedSVG'
                                        alt='Mondia'
                                        className='w-100 logo-graphic'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='mobile-margin-bottom mobile-margin-top my-5'>
                <div className='container-1 bg-dark rounded-1 p-5'>
                    <div className='d-flex flex-wrap'>
                        <div className='col-md-12 col-12 px-5 text-center roll-button mobile-padding-reset'>
                            <p className='mb-5 paragraph-title text-white'>
                                Ready to zoom into your data with Lenses?
                            </p>
                            <a className='bg-white primary-text' href='/start/'>
                                <span data-hover='Try now for free'> Try now for free</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default customers
